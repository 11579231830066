import React from 'react';
import {AppSettings} from "./AppSettings";
import {
    DataList,
    DataListCell,
    DataListItem,
    DataListItemCells,
    DataListItemRow,
    DrawerContentBody,
    EmptyState,
    EmptyStateIcon,
    ExpandableSection,
    Text,
    Title,
    Tooltip
} from "@patternfly/react-core";
import {
    BanIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon
} from "@patternfly/react-icons";
import "./DetailsDrawer.css";
import {LS_KEY_BOLT_DETAILS_EXPANDED_STATES} from "./LocalStorageKeys";

const SensorBoltCalibrationDetails = ({boltId, t, app, refreshData}) => {
    const tag2Html = {
        loadFree: <var>= 0*F<sub>nom</sub></var>,
        preLoad: <var>= 1,2*F<sub>nom</sub></var>,
        cascade1: <var>= 1*F<sub>nom</sub></var>,
        cascade2: <var>= 0,75*F<sub>nom</sub></var>,
        cascade3: <var>= 0,5*F<sub>nom</sub></var>,
        cascade4: <var>= 0,25*F<sub>nom</sub></var>
    }
    const [mounted, setMounted] = React.useState(false)
    const [calibrationDetails, setCalibrationDetails] = React.useState({
        metadata: [],
        assemblyMeasurements: [],
        temperatureProfile: [],
        finalCalibration: []
    })
    const [expandedSections, setExpandedSections] = React.useState(
        JSON.parse(localStorage.getItem(LS_KEY_BOLT_DETAILS_EXPANDED_STATES)) || {
            metadata: true,
            assemblyMeasurements: true,
            temperatureProfile: true,
            finalCalibration: true
        }
    )

    React.useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [setMounted])

    React.useEffect(() => {
        if (!mounted) return
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/sensorBolts/${boltId}/calibrationDetails`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(data => {
                        setCalibrationDetails(data)
                    }).catch(error => console.log(error.message))
                }
            })
            .catch(error => app.showErrorMessage(error.message))
    }, [app, mounted, boltId, refreshData])

    const expandableSection = (key, title, data) => {
        return <ExpandableSection
            key={key}
            toggleText={title}
            isExpanded={expandedSections[key]}
            onToggle={(isExpanded) => {
                const o = {}
                o[`${key}`] = isExpanded
                const e = {...expandedSections, ...o}
                localStorage.setItem(LS_KEY_BOLT_DETAILS_EXPANDED_STATES, JSON.stringify(e))
                setExpandedSections(e)
            }}
        >
            {data.map((section, sectionIndex) => {
                return <DataList key={`${key}:${sectionIndex}`} isCompact aria-label={`section ${key}`}>
                    {section.title &&
                        <DataListItem key={`${key}:${sectionIndex}:t`}>
                            <DataListItemRow>
                                <DataListItemCells dataListCells={[
                                    <DataListCell key="label" isFilled={true}>
                                        <Text style={{fontWeight: 'bold'}}>{section.title} {section.tag && tag2Html[section.tag]}</Text>
                                    </DataListCell>
                                ]}/>
                            </DataListItemRow>
                        </DataListItem>
                    }
                    {section.items.length > 0 && section.items.map((item, index) => {
                        return <DataListItem key={`${key}:${sectionIndex}:${index}`}>
                            <DataListItemRow>
                                <DataListItemCells dataListCells={[
                                    <DataListCell key="label" isFilled={false}>
                                        {item.name}
                                    </DataListCell>,
                                    <DataListCell key="value" isFilled={false} alignRight className="Value">
                                        {item.rating === 'Okay' && <>{item.value} <CheckCircleIcon className="Success StatusIcon"/></>}
                                        {item.rating !== 'Okay' &&
                                            <Tooltip aria="none" aria-live="polite" content={item["ratingHint"]} maxWidth="100%">
                                                <div>
                                                    {item.value}
                                                    {item.rating === 'Warning' &&
                                                        <ExclamationTriangleIcon className="Warning StatusIcon"/>
                                                    }
                                                    {item.rating === 'Error' &&
                                                        <ExclamationCircleIcon className="Danger StatusIcon"/>
                                                    }
                                                </div>
                                            </Tooltip>
                                        }
                                    </DataListCell>
                                ]}/>
                            </DataListItemRow>
                        </DataListItem>
                    })}
                    {section.items.length === 0 &&
                        <DataListItem key={`${key}:${sectionIndex}:e`}>
                            <DataListItemRow>
                                <DataListItemCells dataListCells={[
                                    <DataListCell key="label" isFilled={true}>
                                        <EmptyState isFullHeight>
                                            <EmptyStateIcon icon={BanIcon} />
                                            <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                        </EmptyState>
                                    </DataListCell>
                                ]}/>
                            </DataListItemRow>
                        </DataListItem>
                    }
                </DataList>
            })}
        </ExpandableSection>
    }

    const expandableEmptySection = (key, title) => {
        return <ExpandableSection
            key={key}
            toggleText={title}
            isExpanded={expandedSections[key]}
            onToggle={(isExpanded) => {
                const o = {}
                o[`${key}`] = isExpanded
                const e = {...expandedSections, ...o}
                localStorage.setItem(LS_KEY_BOLT_DETAILS_EXPANDED_STATES, JSON.stringify(e))
                setExpandedSections(e)
            }}
        >
            <DataList key={`${key}`} isCompact aria-label={`section ${key}`}>
                <DataListItem key={`${key}:e`}>
                    <DataListItemRow>
                        <DataListItemCells dataListCells={[
                            <DataListCell key="label" isFilled={true}>
                                <EmptyState isFullHeight>
                                    <EmptyStateIcon icon={BanIcon} />
                                    <Title headingLevel="h5" size="4xl">{t("fe_disc_no-data")}</Title>
                                </EmptyState>
                            </DataListCell>
                        ]}/>
                    </DataListItemRow>
                </DataListItem>
            </DataList>
        </ExpandableSection>
    }

    return <DrawerContentBody className="DetailsDrawer">
        {calibrationDetails.metadata.length > 0 &&
            <>
                {expandableSection("metadata", t("fe_common_generic-details"), calibrationDetails.metadata)}
                {expandableSection("assemblyMeasurements", t("fe_disc_assembly-measurements"), calibrationDetails.assemblyMeasurements)}
                {calibrationDetails.temperatureProfile.length > 0 && expandableSection("temperatureProfile", t("fe_disc_temperature-profile"), calibrationDetails.temperatureProfile)}
                {calibrationDetails.temperatureProfile.length === 0 && expandableEmptySection("temperatureProfile", t("fe_disc_temperature-profile"))}
                {calibrationDetails.finalCalibration.length > 0 && expandableSection("finalCalibration", t("fe_disc_final-calibration"), calibrationDetails.finalCalibration)}
                {calibrationDetails.finalCalibration.length === 0 && expandableEmptySection("finalCalibration", t("fe_disc_final-calibration"))}
            </>
        }
        </DrawerContentBody>
}

export default SensorBoltCalibrationDetails