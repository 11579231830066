import React from 'react';
import {
    TableComposable,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@patternfly/react-table";
import {AppSettings} from "./AppSettings";
import {
    Bullseye,
    Button,
    Drawer,
    DrawerActions,
    DrawerCloseButton,
    DrawerColorVariant,
    DrawerContent,
    DrawerContentBody,
    DrawerHead,
    DrawerPanelContent,
    EmptyState,
    EmptyStateBody,
    EmptyStateIcon,
    EmptyStatePrimary,
    Menu,
    MenuContent,
    MenuItem,
    MenuList,
    MenuToggle,
    Modal,
    ModalVariant,
    PageGroup,
    PageSection,
    PageSectionVariants,
    Pagination,
    Popper,
    SearchInput,
    Tab,
    TabContent,
    Tabs,
    TabTitleIcon,
    TabTitleText,
    Text,
    TextContent,
    TextVariants,
    Title,
    Toolbar,
    ToolbarContent,
    ToolbarFilter,
    ToolbarGroup,
    ToolbarItem,
    ToolbarToggleGroup
} from "@patternfly/react-core";
import {
    CheckCircleIcon,
    CogIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    ExportIcon,
    FilterIcon, MapIcon,
    OutlinedCalendarAltIcon,
    PlayIcon,
    RunningIcon,
    SearchIcon,
    SyncAltIcon
} from "@patternfly/react-icons";
import {DateRangePicker} from "react-date-range";
import i18n from "i18next";
import {de, enUS, fr, it} from "date-fns/locale";
import {stickyChartsDateRanges, stickyChartsInputRanges} from "./StickyChartsDateRanges";
import {addDays, endOfDay, startOfDay} from "date-fns";
import {date2ISOString, formatDateRange} from "./Utils";
import streamSaver from "streamsaver";
import "./SensorBoltPage.css";
import SensorBoltCalibrationDetails from "./SensorBoltCalibrationDetails";
import {LS_KEY_PAGING, LS_KEY_SB_SORTING} from "./LocalStorageKeys";

const SensorBoltPage = ({t, app}) => {
    const defaultSorting = JSON.stringify({"index": 0, "direction": "asc"})
    const [mounted, setMounted] = React.useState(false)
    const [activeSortIndex, setActiveSortIndex] = React.useState(JSON.parse(localStorage.getItem(LS_KEY_SB_SORTING) || defaultSorting).index)
    const [activeSortDirection, setActiveSortDirection] = React.useState(JSON.parse(localStorage.getItem(LS_KEY_SB_SORTING) || defaultSorting).direction)
    const [selectedBoltId, setSelectedBoltId] = React.useState(null)
    const [tableData, setTableData] = React.useState({
        totalMatches: -1, // indicates pending data load
        items: []
    })
    const getSortParams = columnIndex => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection,
            defaultDirection: "asc"
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
            localStorage.setItem(LS_KEY_SB_SORTING, JSON.stringify({"index": index, "direction": direction}))
        },
        columnIndex
    });
    const [page, setPage] = React.useState({
        pageIndex: 1,
        pageSize: Number(localStorage.getItem(LS_KEY_PAGING) || "20")
    });
    const onSetPage = (_event, newPageIndex) => {
        setPage({...page, pageIndex: newPageIndex})
    };
    const onPageSizeSelect = (_event, newPageSize, newPageIndex) => {
        localStorage.setItem(LS_KEY_PAGING, newPageSize.toString())
        setPage({pageIndex: newPageIndex, pageSize: newPageSize})
    };
    const [idPrefix, setIdPrefix] = React.useState('')
    const [orderInfix, setOrderInfix] = React.useState('')
    const [dateRange, setDateRange] = React.useState(null)
    const [status, setStatus] = React.useState(null)
    const [isAttributeMenuOpen, setIsAttributeMenuOpen] = React.useState(false)
    const [activeAttributeMenu, setActiveAttributeMenu] = React.useState('BoltID')
    const [datePickerOpen, setDatePickerOpen] = React.useState(false)
    const [datePickerRange, setDatePickerRange] = React.useState([{
        startDate: startOfDay(addDays(new Date(), -30)),
        endDate: endOfDay(new Date()),
        key: "selection"
    }])
    const [isStatusMenuOpen, setIsStatusMenuOpen] = React.useState(false)
    const [isExportMenuOpen, setIsExportMenuOpen] = React.useState(false)
    const [activeTabKey, setActiveTabKey] = React.useState(0)
    const [refreshData, setRefreshData] = React.useState(false)

    const attributeContainerRef = React.useRef(null)
    const attributeToggleRef = React.useRef(null)
    const attributeMenuRef = React.useRef(null)
    const idFilterRef = React.useRef(null)
    const orderFilterRef = React.useRef(null)
    const statusToggleRef = React.useRef(null)
    const statusMenuRef = React.useRef(null)
    const statusContainerRef = React.useRef(null)
    const exportToggleRef = React.useRef(null)
    const exportMenuRef = React.useRef(null)
    const exportContainerRef = React.useRef(null)
    const registryTabContentRef = React.createRef();
    const operationTabContentRef = React.createRef();
    const chartsTabContentRef = React.createRef();
    const mapTabContentRef = React.createRef();

    React.useEffect(() => {
        const handleAttributeMenuKeys = event => {
            if (!isAttributeMenuOpen) {
                return
            }
            if (attributeMenuRef.current?.contains(event.target) || attributeToggleRef.current?.contains(event.target)) {
                if (event.key === 'Escape' || event.key === 'Tab') {
                    setIsAttributeMenuOpen(!isAttributeMenuOpen)
                    attributeToggleRef.current?.focus()
                }
            }
        }

        const handleAttributeClickOutside = event => {
            if (isAttributeMenuOpen && !attributeMenuRef.current?.contains(event.target)) {
                setIsAttributeMenuOpen(false)
            }
        }

        window.addEventListener('keydown', handleAttributeMenuKeys);
        window.addEventListener('click', handleAttributeClickOutside);

        return () => {
            window.removeEventListener('keydown', handleAttributeMenuKeys);
            window.removeEventListener('click', handleAttributeClickOutside);
        }
    }, [isAttributeMenuOpen, attributeMenuRef])

    React.useEffect(() => {
        const handleStatusMenuKeys = event => {
            if (isStatusMenuOpen && statusMenuRef.current?.contains(event.target)) {
                if (event.key === 'Escape' || event.key === 'Tab') {
                    setIsStatusMenuOpen(!isStatusMenuOpen)
                    statusToggleRef.current?.focus()
                }
            }
        }

        const handleStatusClickOutside = event => {
            if (isStatusMenuOpen && !statusMenuRef.current?.contains(event.target)) {
                setIsStatusMenuOpen(false)
            }
        }

        window.addEventListener('keydown', handleStatusMenuKeys)
        window.addEventListener('click', handleStatusClickOutside)
        return () => {
            window.removeEventListener('keydown', handleStatusMenuKeys)
            window.removeEventListener('click', handleStatusClickOutside)
        }
    }, [isStatusMenuOpen, statusMenuRef])

    React.useEffect(() => {
        const handleExportMenuKeys = event => {
            if (!isExportMenuOpen) {
                return
            }
            if (exportMenuRef.current?.contains(event.target) || exportToggleRef.current?.contains(event.target)) {
                if (event.key === 'Escape' || event.key === 'Tab') {
                    setIsExportMenuOpen(!isExportMenuOpen)
                    exportToggleRef.current?.focus()
                }
            }
        }

        const handleExportClickOutside = event => {
            if (isExportMenuOpen && !exportMenuRef.current?.contains(event.target)) {
                setIsExportMenuOpen(false)
            }
        }

        window.addEventListener('keydown', handleExportMenuKeys);
        window.addEventListener('click', handleExportClickOutside);

        return () => {
            window.removeEventListener('keydown', handleExportMenuKeys);
            window.removeEventListener('click', handleExportClickOutside);
        }
    }, [isExportMenuOpen, exportMenuRef])

    const onAttributeToggleClick = ev => {
        ev.stopPropagation()
        setTimeout(() => {
            if (attributeMenuRef.current) {
                const firstElement = attributeMenuRef.current.querySelector('li > button:not(:disabled)')
                firstElement && firstElement.focus()
            }
        }, 0)
        setIsAttributeMenuOpen(!isAttributeMenuOpen)
    }

    const onStatusToggleClick = ev => {
        ev.stopPropagation()
        setTimeout(() => {
            if (statusMenuRef.current) {
                const firstElement = statusMenuRef.current.querySelector('li > button:not(:disabled)')
                firstElement && firstElement.focus()
            }
        }, 0)
        setIsStatusMenuOpen(!isStatusMenuOpen)
    }

    const onExportToggleClick = ev => {
        ev.stopPropagation()
        setTimeout(() => {
            if (exportMenuRef.current) {
                const firstElement = exportMenuRef.current.querySelector('li > button:not(:disabled)')
                firstElement && firstElement.focus()
            }
        }, 0)
        setIsExportMenuOpen(!isExportMenuOpen)
    }

    function onStatusSelect(event, itemId) {
        if (typeof itemId === 'undefined') {
            return
        }
        setStatus(itemId.toString())
        setPage({...page, pageIndex: 1})
        setIsStatusMenuOpen(!isStatusMenuOpen)
    }

    async function exportAssemblyMeasurements() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${app["jwt"]}`
            }
        }
        const idPrefixQuery = idPrefix ? `&idPrefix=${idPrefix}` : ""
        const orderInfixQuery = orderInfix ? `&orderInfix=${orderInfix}` : ""
        const dateRangeQuery = dateRange ? `&dateFrom=${date2ISOString(dateRange.startDate)}&dateUntil=${date2ISOString(dateRange.endDate)}` : ""
        const statusQuery = status ? `&calibrationIssue=${status}` : ""
        const url = `${AppSettings.rootPath}/v1/sensorBolts/assemblyMeasurements/csv?1=1${idPrefixQuery}${orderInfixQuery}${dateRangeQuery}${statusQuery}`

        try {
            const response = await fetch(url, requestInit)
            if (response.status === 401) {
                app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream("assembly_measurements.csv")

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            app.showErrorMessage(error.message)
        }
    }

    async function exportTemperatureProfiles() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${app["jwt"]}`
            }
        }
        const idPrefixQuery = idPrefix ? `&idPrefix=${idPrefix}` : ""
        const orderInfixQuery = orderInfix ? `&orderInfix=${orderInfix}` : ""
        const dateRangeQuery = dateRange ? `&dateFrom=${date2ISOString(dateRange.startDate)}&dateUntil=${date2ISOString(dateRange.endDate)}` : ""
        const statusQuery = status ? `&calibrationIssue=${status}` : ""
        const url = `${AppSettings.rootPath}/v1/sensorBolts/temperatureProfiles/csv?1=1${idPrefixQuery}${orderInfixQuery}${dateRangeQuery}${statusQuery}`

        try {
            const response = await fetch(url, requestInit)
            if (response.status === 401) {
                app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream("temperature_profiles.csv")

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            app.showErrorMessage(error.message)
        }
    }

    async function exportFinalCalibrations() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${app["jwt"]}`
            }
        }
        const idPrefixQuery = idPrefix ? `&idPrefix=${idPrefix}` : ""
        const orderInfixQuery = orderInfix ? `&orderInfix=${orderInfix}` : ""
        const dateRangeQuery = dateRange ? `&dateFrom=${date2ISOString(dateRange.startDate)}&dateUntil=${date2ISOString(dateRange.endDate)}` : ""
        const statusQuery = status ? `&calibrationIssue=${status}` : ""
        const url = `${AppSettings.rootPath}/v1/sensorBolts/finalCalibrations/csv?1=1${idPrefixQuery}${orderInfixQuery}${dateRangeQuery}${statusQuery}`

        try {
            const response = await fetch(url, requestInit)
            if (response.status === 401) {
                app.sessionExpired()
            } else if (!response.ok) {
                response.text().then(message => app.showErrorMessage(message || response.statusText))
            } else {
                const readableStream = response.body
                const fileStream = streamSaver.createWriteStream("final_calibrations.csv")

                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream)
                } else {
                    window.writer = fileStream.getWriter()
                    const reader = readableStream.getReader()
                    const pump = () => reader.read()
                        .then(res => res.done
                            ? window.writer.close()
                            : window.writer.write(res.value).then(pump))

                    await pump()
                }
            }
        } catch (error) {
            app.showErrorMessage(error.message)
        }
    }

    async function onExportSelect(event, itemId) {
        console.log(itemId)
        if (typeof itemId === 'undefined') {
            return
        }
        setIsExportMenuOpen(!isExportMenuOpen)
        if (itemId === 'AssemblyMeasurements') {
            await exportAssemblyMeasurements()
        } else if (itemId === 'TemperatureProfiles') {
            await exportTemperatureProfiles()
        } else {
            await exportFinalCalibrations()
        }
    }

    const attributeToggle = <MenuToggle
        ref={attributeToggleRef}
        onClick={onAttributeToggleClick}
        isExpanded={isAttributeMenuOpen}
        icon={<FilterIcon/>}
    />

    const attributeMenu = <Menu
        ref={attributeMenuRef}
        onSelect={(_ev, itemId) => {
            setActiveAttributeMenu(itemId?.toString())
            setIsAttributeMenuOpen(!isAttributeMenuOpen)
            setTimeout(() => {
                if (itemId === 'BoltID') {
                    idFilterRef.current?.focus()
                } else if (itemId === 'OrderNumber') {
                    orderFilterRef.current?.focus()
                } else if (itemId === 'RegisteredAt') {
                    setDatePickerOpen(true)
                } else if (itemId === 'MissingCalibration') {
                    setIsStatusMenuOpen(true)
                }
            }, 0)
        }}
        selected={activeAttributeMenu}
    >
        <MenuContent>
            <MenuList>
                <MenuItem itemId="BoltID">{t("fe_common_serial-number")}</MenuItem>
                <MenuItem itemId="OrderNumber">{t("fe_common_order-number")}</MenuItem>
                <MenuItem itemId="MissingCalibration">{t("fe_common_missing_calibration")}</MenuItem>
                <MenuItem itemId="RegisteredAt">{t("fe_common_registered_at")}</MenuItem>
            </MenuList>
        </MenuContent>
    </Menu>

    const attributeDropdown = <Popper
        trigger={attributeToggle}
        popper={attributeMenu}
        popperMatchesTriggerWidth={false}
        appendTo={attributeContainerRef.current || undefined}
        isVisible={isAttributeMenuOpen}
    />

    const statusToggle = <MenuToggle
        ref={statusToggleRef}
        onClick={onStatusToggleClick}
        isExpanded={isStatusMenuOpen}>
        {t("fe_common_filter_missing_calibration")}
    </MenuToggle>

    const statusMenu = <Menu
        ref={statusMenuRef}
        id="attribute-search-status-menu"
        onSelect={onStatusSelect}
        selected={status}
    >
        <MenuContent>
            <MenuList>
                <MenuItem itemId="TemperatureProfile">{t("fe_disc_temperature-profile")}</MenuItem>
                <MenuItem itemId="FinalCalibration">{t("fe_disc_final-calibration")}</MenuItem>
            </MenuList>
        </MenuContent>
    </Menu>

    const statusSelect = <div ref={statusContainerRef}>
        <Popper trigger={statusToggle} popper={statusMenu} appendTo={statusContainerRef.current || undefined} isVisible={isStatusMenuOpen} />
    </div>

    const exportToggle =
        <MenuToggle
            ref={exportToggleRef}
            disabled={tableData.items.length === 0}
            onClick={onExportToggleClick}
            icon={<ExportIcon/>}
            isExpanded={isExportMenuOpen}>
            {t("fe_action_export-csv")}
        </MenuToggle>

    const exportMenu = <Menu ref={exportMenuRef} id="attribute-search-export-menu" onSelect={onExportSelect}>
        <MenuContent>
            <MenuList>
                <MenuItem itemId="AssemblyMeasurements">{t("fe_disc_assembly-measurements")}</MenuItem>
                <MenuItem itemId="TemperatureProfiles">{t("fe_disc_temperature-profiles")}</MenuItem>
                <MenuItem itemId="FinalCalibrations">{t("fe_disc_final-calibrations")}</MenuItem>
            </MenuList>
        </MenuContent>
    </Menu>

    const exportSelect = <div ref={exportContainerRef}>
        <Popper
            trigger={exportToggle}
            popper={exportMenu}
            popperMatchesTriggerWidth={false}
            appendTo={exportContainerRef.current || undefined}
            isVisible={isExportMenuOpen}
        />
    </div>

    const idPrefixSearchInput =
        <SearchInput
            ref={idFilterRef}
            placeholder={t("fe_common_filter_snr")}
            value={idPrefix}
            onChange={(value) => {setIdPrefix(value); setPage({...page, pageIndex: 1})}}
            onClear={() => setIdPrefix('')}
        />

    const orderInfixSearchInput =
        <SearchInput
            ref={orderFilterRef}
            placeholder={t("fe_common_filter_order_nr")}
            value={orderInfix}
            onChange={(value) => {setOrderInfix(value); setPage({...page, pageIndex: 1})}}
            onClear={() => setOrderInfix('')}
        />

    const dateRangeButton =
        <Button
            variant="control"
            aria-label="date-range"
            onClick={() => setDatePickerOpen(true)}
            icon={<OutlinedCalendarAltIcon/>}>{t("fe_common_filter_reg_date")}</Button>

    const closeDateRangePicker = () => {
        setDatePickerOpen(false)
    }

    const confirmDateRange = () => {
        setDateRange({startDate: datePickerRange[0].startDate, endDate: datePickerRange[0].endDate})
        setPage({...page, pageIndex: 1})
        setDatePickerOpen(false)
    }

    const dateRangeChanged = (item) => {
        const { startDate, endDate } = item["selection"]
        setDatePickerRange([{
            startDate: startDate,
            endDate: endDate,
            key: "selection"
        }])
    }

    const datePicker =
        <Modal
            width={950}
            variant={ModalVariant.small}
            title={t("fe_chart_select-period")}
            isOpen={true}
            onClose={closeDateRangePicker}
            actions={[
                <Button key="confirm" variant="primary" onClick={confirmDateRange}>{t("fe_common_ok")}</Button>,
                <Button key="cancel" variant="link" onClick={closeDateRangePicker}>{t("fe_common_cancel")}</Button>
            ]}
        >
            <DateRangePicker
                onChange={dateRangeChanged}
                showSelectionPreview
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={datePickerRange}
                direction="horizontal"
                locale={
                    i18n.language.startsWith("de") ? de
                        : i18n.language.startsWith("fr") ? fr
                            : i18n.language.startsWith("it") ? it
                                : enUS
                }
                maxDate={new Date()}
                dateDisplayFormat={t("fe_chart_period-selector-date-pattern")}
                monthDisplayFormat="MMMM yyyy"
                staticRanges={stickyChartsDateRanges(t)}
                inputRanges={stickyChartsInputRanges(t)}
            />
        </Modal>

    const clearAllFilters = () => {
        setIdPrefix('')
        setOrderInfix('')
        setStatus(null)
        setDateRange(null)
    }

    const toolbar =
        <Toolbar
            id="attribute-search-filter-toolbar"
            clearAllFilters={clearAllFilters}>
            <ToolbarContent className="NoPadding" clearFiltersButtonText={t("fe_common_clear_all_filters")}>
                <ToolbarToggleGroup toggleIcon={<FilterIcon/>} breakpoint="xl">
                    <ToolbarGroup variant="filter-group" ref={attributeContainerRef}>
                        <ToolbarItem>{attributeDropdown}</ToolbarItem>
                        <ToolbarFilter
                            chips={idPrefix !== '' ? [idPrefix] : []}
                            deleteChip={() => setIdPrefix('')}
                            deleteChipGroup={() => setIdPrefix('')}
                            categoryName={t("fe_common_serial-number")}
                            showToolbarItem={activeAttributeMenu === 'BoltID'}>
                            {idPrefixSearchInput}
                        </ToolbarFilter>
                        <ToolbarFilter
                            chips={orderInfix !== '' ? [orderInfix] : []}
                            deleteChip={() => setOrderInfix('')}
                            deleteChipGroup={() => setOrderInfix('')}
                            categoryName={t("fe_common_sap_order_nr")}
                            showToolbarItem={activeAttributeMenu === 'OrderNumber'}>
                            {orderInfixSearchInput}
                        </ToolbarFilter>
                        <ToolbarFilter
                            chips={status !== null ? [status === 'TemperatureProfile' ? t("fe_disc_temperature-profile") : t("fe_disc_final-calibration")] : []}
                            deleteChip={() => setStatus(null)}
                            deleteChipGroup={() => setStatus(null)}
                            categoryName={t("fe_common_not-available")}
                            showToolbarItem={activeAttributeMenu === 'MissingCalibration'}>
                            {statusSelect}
                        </ToolbarFilter>
                        <ToolbarFilter
                            chips={dateRange != null ? [formatDateRange(dateRange.startDate, dateRange.endDate)] : []}
                            deleteChip={() => setDateRange(null)}
                            deleteChipGroup={() => setDateRange(null)}
                            categoryName={t("fe_common_registered_at")}
                            showToolbarItem={activeAttributeMenu === 'RegisteredAt'}>
                            {dateRangeButton}
                        </ToolbarFilter>
                    </ToolbarGroup>
                </ToolbarToggleGroup>
                <ToolbarItem variant="separator" />
                <ToolbarItem>
                    {exportSelect}
                </ToolbarItem>
                <ToolbarItem variant="separator" />
                <ToolbarItem>
                    <Button variant="control" onClick={() => setRefreshData(!refreshData)}>
                        <SyncAltIcon />
                    </Button>
                </ToolbarItem>
                {tableData.totalMatches >= 0 &&
                    <ToolbarItem variant="pagination">
                        <Pagination
                            perPageComponent="button"
                            itemCount={tableData.totalMatches}
                            perPage={page.pageSize}
                            page={page.pageIndex}
                            widgetId="pagination"
                            onSetPage={onSetPage}
                            onPerPageSelect={onPageSizeSelect}
                            isCompact
                        />
                    </ToolbarItem>
                }
            </ToolbarContent>
        </Toolbar>

    const tabs = <Tabs activeKey={activeTabKey} onSelect={(e, i) => setActiveTabKey(i)} aria-label="Tab" role="region">
        <Tab eventKey={0} title={<><TabTitleIcon><CogIcon/></TabTitleIcon><TabTitleText>{t("fe_common_registry")}</TabTitleText></>} tabContentId="registry_tab_id" tabContentRef={registryTabContentRef} />
        <Tab eventKey={1} title={<><TabTitleIcon><PlayIcon/></TabTitleIcon><TabTitleText>{t("fe_disc_provisioning-title")}</TabTitleText></>} tabContentId="operation_tab_id" tabContentRef={operationTabContentRef} />
        <Tab eventKey={2} title={<><TabTitleIcon><RunningIcon/></TabTitleIcon><TabTitleText>{t("fe_common_charts")}</TabTitleText></>} tabContentId="charts_tab_id" tabContentRef={chartsTabContentRef} />
        <Tab eventKey={3} title={<><TabTitleIcon><MapIcon/></TabTitleIcon><TabTitleText>{t("fe_common_map")}</TabTitleText></>} tabContentId="map_tab_id" tabContentRef={mapTabContentRef} />
    </Tabs>

    React.useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [setMounted])

    const loadPage = React.useCallback(() => {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${app["jwt"]}`
            }
        }
        const sortBy = activeSortIndex === 0 ? "BoltId" : activeSortIndex === 2 ? "OrderNumber" : "RegisteredAt"
        const descending = activeSortDirection !== "asc"
        const idPrefixQuery = idPrefix ? `&idPrefix=${idPrefix}` : ""
        const orderInfixQuery = orderInfix ? `&orderInfix=${orderInfix}` : ""
        const dateRangeQuery = dateRange ? `&dateFrom=${date2ISOString(dateRange.startDate)}&dateUntil=${date2ISOString(dateRange.endDate)}`: ""
        const statusQuery = status ? `&calibrationIssue=${status}` : ""

        fetch(`${AppSettings.rootPath}/v1/sensorBolts/summary?page=${page.pageIndex}&pageSize=${page.pageSize}&sortBy=${sortBy}&descending=${descending}${idPrefixQuery}${orderInfixQuery}${dateRangeQuery}${statusQuery}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => app.showErrorMessage(message || response.statusText))
                } else {
                    response.json().then(data => {
                            mounted && setTableData(data)
                    }).catch(error => console.log(error.message))
                }
            })
            .catch(error => app.showErrorMessage(error.message))
    }, [app, mounted, activeSortIndex, activeSortDirection, page, idPrefix, orderInfix, dateRange, status])

    React.useEffect(() => {
        loadPage()
    }, [loadPage, mounted, refreshData])

    const selectBolt = (boltId) => {
        if (selectedBoltId === boltId) {
            setSelectedBoltId(null)
        } else {
            setSelectedBoltId(boltId)
        }
    }

    return <Drawer isExpanded={selectedBoltId !== null} position="right">
        <DrawerContent panelContent={
            <DrawerPanelContent colorVariant={DrawerColorVariant.light200} minSize="600px">
                <DrawerHead>
                    <Title headingLevel="h1">{t("fe_bolt_calibration_title")}</Title>
                    <Text component={TextVariants.h6}>{selectedBoltId}</Text>
                    <DrawerActions>
                        <DrawerCloseButton onClick={() => setSelectedBoltId(null)}/>
                    </DrawerActions>
                </DrawerHead>
                {selectedBoltId !== null && <SensorBoltCalibrationDetails boltId={selectedBoltId} refreshData={refreshData} t={t} app={app}/>}
            </DrawerPanelContent>
        }>
            <DrawerContentBody className="PageBackground">
                <PageGroup>
                    <PageSection className="NoBottomPadding" variant={PageSectionVariants.light}>
                        <TextContent>
                            <Text component="h1">{t("fe_bolt_title")}</Text>
                        </TextContent>
                        {toolbar}
                        {datePickerOpen && datePicker}
                        {tabs}
                    </PageSection>
                    <PageSection variant={PageSectionVariants.default} className="PageContent">
                        <TabContent eventKey={0} id="registry_tab_id" ref={registryTabContentRef}>
                            {tableData.totalMatches >= 0 &&
                                <TableComposable variant="compact" borders={true}>
                                    <Thead>
                                        <Tr>
                                            <Th sort={getSortParams(0)}>{t("fe_common_serial-number")}</Th>
                                            <Th>{t("fe_common_calibration_status")}</Th>
                                            <Th sort={getSortParams(2)}>{t("fe_common_order-number")}</Th>
                                            <Th>{t("fe_disc_size")}</Th>
                                            <Th>{t("fe_bolt_material")}</Th>
                                            <Th sort={getSortParams(5)}>{t("fe_common_registered_at")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {tableData.totalMatches > 0 && tableData.items.map((rowData, rowIndex) =>
                                            <Tr isSelectable isHoverable key={rowIndex} isRowSelected={selectedBoltId === rowData["boltId"]} onRowClick={()=> selectBolt(rowData["boltId"])}>
                                                <Td dataLabel={t("fe_common_serial-number")}>{rowData["boltId"]}</Td>
                                                <Td dataLabel={t("fe_common_status")}>
                                                    {rowData["rating"] === 'Okay' && <CheckCircleIcon className="Success BoltCalibrationStatusIcon"/>}
                                                    {rowData["rating"] === 'Warning' && <ExclamationTriangleIcon className="Warning BoltCalibrationStatusIcon"/>}
                                                    {rowData["rating"] === 'Error' && <ExclamationCircleIcon className="Danger BoltCalibrationStatusIcon"/>}
                                                    {rowData["ratingLabel"]}
                                                </Td>
                                                <Td dataLabel={t("fe_common_order-number")}>{rowData["sapOrderNumber"]}</Td>
                                                <Td dataLabel={t("fe_disc_size")}>{rowData["size"] || ""}</Td>
                                                <Td dataLabel={t("fe_bolt_material")}>{rowData["material"] || ""}</Td>
                                                <Td dataLabel={t("fe_common_registered_at")}>{rowData["registeredAt"]}</Td>
                                            </Tr>
                                        )}
                                        {tableData.totalMatches === 0 && <Tr>
                                            <Td colSpan={6}>
                                                <Bullseye>
                                                    <EmptyState>
                                                        <EmptyStateIcon icon={SearchIcon} />
                                                        <Title size="lg" headingLevel="h4">{t("fe_common_no_results")}</Title>
                                                        <EmptyStateBody>{t("fe_common_no_results_hint")}</EmptyStateBody>
                                                        <EmptyStatePrimary>
                                                            <Button variant="link" onClick={clearAllFilters}>{t("fe_common_clear_all_filters")}</Button>
                                                        </EmptyStatePrimary>
                                                    </EmptyState>
                                                </Bullseye>
                                            </Td>
                                        </Tr>}
                                    </Tbody>
                                </TableComposable>
                            }
                        </TabContent>
                        <TabContent eventKey={1} id="operation_tab_id" ref={operationTabContentRef}>
                            <div></div>
                        </TabContent>
                        <TabContent eventKey={2} id="charts_tab_id" ref={chartsTabContentRef}>
                            <div></div>
                        </TabContent>
                        <TabContent eventKey={3} id="map_tab_id" ref={mapTabContentRef}>
                            <div></div>
                        </TabContent>
                    </PageSection>
                </PageGroup>
            </DrawerContentBody>
        </DrawerContent>
    </Drawer>
}

export default SensorBoltPage