import React, { Component } from "react";
import {
    BrowserRouter,
    Route
} from "react-router-dom";
import { Translation } from "react-i18next";

import {
    Brand,
    Button,
    Drawer,
    DrawerContent,
    DrawerContentBody,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    Flex,
    FlexItem,
    Page,
    PageHeader,
    PageHeaderTools,
    PageHeaderToolsItem,
    PageSidebar,
    Title
} from "@patternfly/react-core";

import {
    MonitoringIcon,
    PowerOffIcon,
    UserIcon
} from "@patternfly/react-icons";

import SideNavigation from "./SideNavigation";
import Dashboard from "./Dashboard";
import GatewayList from "./GatewayList";
import AnchorList from "./AnchorList";
import SensorDiscPage from "./SensorDiscPage";
import SensorBoltPage from "./SensorBoltPage";
import SimCardList from "./SimCardList";
import FirmwareList from "./FirmwareList";
import SimulationList from "./SimulationList";
import UserList from "./UserList";
import { AppSettings } from "./AppSettings";
import StickyCharts from "./StickyCharts";
import { registerCharts } from "./registerCharts";
import {
    LS_KEY_NAV_OPEN,
    LS_KEY_STICKY_CHARTS_OPEN,
    LS_KEY_STICKY_CHART_CURRENT,
    LS_KEY_STICKY_CHART_GATEWAYS
} from "./LocalStorageKeys";

import Logo from "./images/fischer_logo.svg";
import "./App.css";

registerCharts()

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isNavOpen: localStorage.getItem(LS_KEY_NAV_OPEN) !== "n",
            isPageHeaderDropDownOpen: false,
            isStickyChartsExpanded: localStorage.getItem(LS_KEY_STICKY_CHARTS_OPEN) === "y",
            isDrawerAnimationComplete: false,
            stickyChartGateways: JSON.parse(localStorage.getItem(LS_KEY_STICKY_CHART_GATEWAYS) || "[]"),
            stickyChartCurrent: localStorage.getItem(LS_KEY_STICKY_CHART_CURRENT) || false
        }

        this.onNavToggle = this.onNavToggle.bind(this)
        this.onTogglePageHeaderDropDown = this.onTogglePageHeaderDropDown.bind(this)
        this.onSelectPageHeaderDropDown = this.onSelectPageHeaderDropDown.bind(this)
        this.onClickSignOut = this.onClickSignOut.bind(this)
        this.onToggleStickyCharts = this.onToggleStickyCharts.bind(this)
        this.addGatewayToStickyCharts = this.addGatewayToStickyCharts.bind(this)
        this.selectStickyGateway = this.selectStickyGateway.bind(this)
        this.removeGatewayFromStickyCharts = this.removeGatewayFromStickyCharts.bind(this)
        this.onDrawerExpand = this.onDrawerExpand.bind(this)
    }

    render() {
        const { isNavOpen } = this.state

        return (
            <Translation>
                { t =>
                    <BrowserRouter>
                        <Page header={
                            <PageHeader
                                logo={
                                    <Flex>
                                        <FlexItem>
                                            <Brand className="FischerLogo" src={Logo} alt="fischer Logo"/>
                                        </FlexItem>
                                        <FlexItem>
                                            <Title className="PageTitle" headingLevel="h1" size="3xl"><b>IoT</b>&nbsp;&nbsp;<span className="Semi">BackOffice{AppSettings.profile !== "prod" && ` : ${AppSettings.profile}`}</span></Title>
                                        </FlexItem>
                                    </Flex>
                                }
                                headerTools={<PageHeaderTools>
                                    <PageHeaderToolsItem>
                                        <Button
                                            variant="plain"
                                            isInline
                                            isDisabled={this.state.stickyChartGateways.length === 0}
                                            onClick={this.onToggleStickyCharts}>
                                            <MonitoringIcon/>
                                        </Button>
                                    </PageHeaderToolsItem>
                                    <PageHeaderToolsItem>
                                        <Dropdown
                                            onSelect={this.onSelectPageHeaderDropDown}
                                            toggle={<DropdownToggle toggleIndicator={null} onToggle={this.onTogglePageHeaderDropDown}><UserIcon />&nbsp;&nbsp;&nbsp;{this.props.app.name}</DropdownToggle>}
                                            isOpen={this.state.isPageHeaderDropDownOpen}
                                            isPlain
                                            dropdownItems={[<DropdownItem key="sign-out" icon={<PowerOffIcon />} onClick={this.onClickSignOut}>{t("fe_app_logout")}</DropdownItem>]}
                                        />
                                    </PageHeaderToolsItem>
                                </PageHeaderTools>}
                                showNavToggle
                                isNavOpen={isNavOpen}
                                onNavToggle={this.onNavToggle}
                            />
                        } sidebar={
                            <PageSidebar nav={<SideNavigation activeItem={0} app={this.props.app}/>} isNavOpen={isNavOpen}/>
                        }>
                            <Drawer isInline isExpanded={this.state.isStickyChartsExpanded} position="bottom"
                                    onExpand={this.onDrawerExpand}>
                                <DrawerContent panelContent={
                                    this.state.isStickyChartsExpanded &&
                                        <StickyCharts
                                            app={this.props.app}
                                            isExpanded={this.state.isStickyChartsExpanded}
                                            fullyExpanded={this.state.isDrawerAnimationComplete}
                                            gateways={this.state.stickyChartGateways}
                                            current={this.state.stickyChartCurrent}
                                            isNavOpen={this.state.isNavOpen}
                                            selectStickyGateway={this.selectStickyGateway}
                                            removeGateway={this.removeGatewayFromStickyCharts}
                                        />
                                }>
                                    <DrawerContentBody className="PageContent">
                                        <Route exact path={`${AppSettings.rootPath}/`}>
                                            <Dashboard app={this.props.app}
                                                       addGatewayToStickyCharts={this.addGatewayToStickyCharts}/>
                                        </Route>
                                        <Route path={`${AppSettings.rootPath}/sensorGateways`}>
                                            <GatewayList app={this.props.app}
                                                         addGatewayToStickyCharts={this.addGatewayToStickyCharts}/>
                                        </Route>
                                        <Route path={`${AppSettings.rootPath}/sensorAnchors`}>
                                            <AnchorList app={this.props.app}/>
                                        </Route>
                                        <Route path={`${AppSettings.rootPath}/sensorDisc`}>
                                            <SensorDiscPage app={this.props.app} isNavOpen={this.state.isNavOpen}/>
                                        </Route>
                                        <Route path={`${AppSettings.rootPath}/sensorBolt`}>
                                            <SensorBoltPage t={t} app={this.props.app}/>
                                        </Route>
                                        <Route path={`${AppSettings.rootPath}/simCards`}>
                                            <SimCardList app={this.props.app}/>
                                        </Route>
                                        <Route path={`${AppSettings.rootPath}/firmware`}>
                                            <FirmwareList app={this.props.app}/>
                                        </Route>
                                        <Route path={`${AppSettings.rootPath}/simulator`}>
                                            <SimulationList app={this.props.app}/>
                                        </Route>
                                        {
                                            this.props.app["role"] === "Admin" && (
                                                <Route path={`${AppSettings.rootPath}/users`}>
                                                    <UserList app={this.props.app}/>
                                                </Route>
                                            )
                                        }
                                    </DrawerContentBody>
                                </DrawerContent>
                            </Drawer>
                        </Page>
                    </BrowserRouter>
                }
            </Translation>
        )
    }

    onNavToggle() {
        this.setState(prevState => {
            const isOpen = !prevState.isNavOpen
            localStorage.setItem(LS_KEY_NAV_OPEN, isOpen ? "y" : "n")
            return {
                isNavOpen: isOpen
            }
        })
    }

    onTogglePageHeaderDropDown(isOpen) {
        this.setState({isPageHeaderDropDownOpen: isOpen})
    }

    onSelectPageHeaderDropDown() {
        this.setState(prevState => {
            return {
                isPageHeaderDropDownOpen: !prevState.isPageHeaderDropDownOpen
            }
        })
    }

    onClickSignOut() {
        this.props.app.signOut()
    }

    onToggleStickyCharts() {
        this.setState(prevState => {
            const isOpen = !prevState.isStickyChartsExpanded
            localStorage.setItem(LS_KEY_STICKY_CHARTS_OPEN, isOpen ? "y" : "n")
            return {
                isStickyChartsExpanded: isOpen,
                isDrawerAnimationComplete: false
            }
        })
    }

    addGatewayToStickyCharts(serialNumber) {
        this.setState(prevState => {
            let stickyChartGateways = prevState.stickyChartGateways
            if (!stickyChartGateways.find(s => s === serialNumber)) {
                stickyChartGateways.push(serialNumber)
                stickyChartGateways = stickyChartGateways.sort()
            }
            localStorage.setItem(LS_KEY_NAV_OPEN, "y")
            localStorage.setItem(LS_KEY_STICKY_CHART_GATEWAYS, JSON.stringify(stickyChartGateways))
            localStorage.setItem(LS_KEY_STICKY_CHART_CURRENT, serialNumber)
            return {
                isStickyChartsExpanded: true,
                stickyChartGateways: stickyChartGateways,
                stickyChartCurrent: serialNumber
            }
        })
    }

    selectStickyGateway(serialNumber) {
        localStorage.setItem(LS_KEY_STICKY_CHART_CURRENT, serialNumber)
        this.setState({
            stickyChartCurrent: serialNumber
        })
    }

    removeGatewayFromStickyCharts(serialNumber) {
        this.setState(prevState => {
            const prevIndex = prevState.stickyChartGateways.findIndex(s => s === prevState.stickyChartCurrent)
            const filteredStickyChartGateways = prevState.stickyChartGateways.filter(s => s !== serialNumber)
            const currentSerialNumber = serialNumber !== prevState.stickyChartCurrent ? prevState.stickyChartCurrent :
                prevIndex < filteredStickyChartGateways.length ? filteredStickyChartGateways[prevIndex] :
                    prevIndex > 0 ? filteredStickyChartGateways[prevIndex-1] :
                        false
            localStorage.setItem(LS_KEY_STICKY_CHARTS_OPEN, filteredStickyChartGateways.length > 0 ? "y" : "n")
            localStorage.setItem(LS_KEY_STICKY_CHART_GATEWAYS, JSON.stringify(filteredStickyChartGateways))
            localStorage.setItem(LS_KEY_STICKY_CHART_CURRENT, currentSerialNumber)
            return {
                isStickyChartsExpanded: filteredStickyChartGateways.length > 0,
                stickyChartGateways: filteredStickyChartGateways,
                stickyChartCurrent: currentSerialNumber
            }
        })
    }

    onDrawerExpand() {
        this.setState({
            isDrawerAnimationComplete: true
        })
    }
}

export default App