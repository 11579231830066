export const LS_KEY_NAV_OPEN = "NV:Open"
export const LS_KEY_STICKY_CHARTS_OPEN = "SC:IsOpen"
export const LS_KEY_STICKY_CHART_GATEWAYS = "SC:Gateways"
export const LS_KEY_STICKY_CHART_CURRENT = "SC:Current"
export const LS_KEY_PANEL_SIZE = "SC:PanelSize"
export const LS_KEY_CHART_HEIGHT = "SC:ChartHeight"
export const LS_KEY_SHOW_FORCES = "SC:ShowForces"
export const LS_KEY_SHOW_TEMPERATURES = "SC:ShowTemperatures"
export const LS_KEY_SMOOTH_LINES = "SC:SmoothLines"
export const LS_KEY_SHOW_DOTS = "SC:ShowDots"
export const LS_KEY_FILL_AREA = "SC:FillArea"
export const LS_KEY_NATURAL_SCALING = "SC:NaturalScaling"
export const LS_KEY_CHART_CONTENT = "SD:ChartContent"
export const LS_KEY_BOLT_DETAILS_EXPANDED_STATES = "SB:Details:ExpandedStates"
export const LS_KEY_PAGING = "Paging"
export const LS_KEY_SB_SORTING = "SB:Sorting"